// Vérification des dépendances globales
console.log('Checking Vue...', window.Vue);
console.log('Checking BootstrapVue...', window.BootstrapVue);
console.log('Checking VueCustomElement...', window.VueCustomElement);

if (!window.Vue) {
    throw new Error('Vue is required')
}

if (!window.BootstrapVue) {
    throw new Error('bootstrap-vue is required')
}

if (!window.VueCustomElement) {
    throw new Error('vue-custom-element is required')
}

const Vue = window.Vue

// Installation des plugins
console.log('Installing plugins...')
Vue.use(window.BootstrapVue)
Vue.use(window.VueCustomElement)

// Importation des composants
import DemoComponent from './frontend/components/Demo.vue'
import AppNavbarComponent from './frontend/components/AppNavbar.vue'

// Enregistrement des composants
console.log('Registering components...')
Vue.customElement('btw-demo', DemoComponent)
Vue.customElement('btw-app-navbar', AppNavbarComponent)

// Setup des composables
import { setupComposables } from './composables-bridge'
setupComposables(Vue)

export {
    DemoComponent,
    AppNavbarComponent
}