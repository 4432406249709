import { useNavigation as originalUseNavigation } from './frontend/composables/useNavigation'
import { ref, computed, watch } from 'vue'

// Adaptateurs pour Vue2
const legacyAdapters = {
    useApiEndpoint: () => {
        const route = legacyAdapters.useRoute()
        const endpoint = useState('apiEndpoint', () => {
            const urlEndpoint = route.query.endpoint
            if (urlEndpoint) {
                localStorage.setItem('api_endpoint', urlEndpoint as string)
                return urlEndpoint
            }
            return localStorage.getItem('api_endpoint') || '/api/v2'
        })
        return { endpoint }
    },
    
    useAuth: () => {
        const user = useState('auth.user', () => null)
        const token = useState('auth.token', () => 
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || null
        )
        
        return {
            user,
            token,
            isAuthenticated: computed(() => !!token.value),
            logout: async () => {
                window.location.href = '/logout'
            }
        }
    },
    
    useAuthStore: () => {
        const { user, token, isAuthenticated } = legacyAdapters.useAuth()
        return { user, token, isAuthenticated }
    },
    
    navigateTo: (path) => {
        window.location.href = path
        return Promise.resolve()
    },

    useState: (key, init) => {
        const state = ref(init?.() ?? null)
        return state
    },

    useRoute: () => ({
        path: window.location.pathname,
        query: Object.fromEntries(new URLSearchParams(window.location.search)),
        params: {},
        name: '',
        meta: {}
    }),

    useCookie: (name: string) => {
        const value = ref(document.cookie.split('; ')
            .find(row => row.startsWith(name + '='))
            ?.split('=')[1] || null)
        
        watch(value, (newValue) => {
            if (newValue === null) {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
            } else {
                document.cookie = `${name}=${newValue}; path=/`
            }
        })
        
        return value
    }
}

// Création d'une version adaptée de useNavigation
const adaptedUseNavigation = () => {
    const { menuItems, isLoading, error, fetchMenuItems } = originalUseNavigation()
    return {
        menuItems,
        isLoading,
        error,
        fetchMenuItems
    }
}

// Injection globale des adaptateurs et des fonctions de composition
if (typeof window !== 'undefined') {
    Object.assign(window, {
        useApiEndpoint: legacyAdapters.useApiEndpoint,
        useAuth: legacyAdapters.useAuth,
        useAuthStore: legacyAdapters.useAuthStore,
        useState: legacyAdapters.useState,
        useNavigation: adaptedUseNavigation,
        useRoute: legacyAdapters.useRoute,
        // Ajout des fonctions de composition Vue
        ref,
        computed,
        useCookie: legacyAdapters.useCookie
    })
}

// Export des fonctions pour les imports directs
export const navigateTo = legacyAdapters.navigateTo
export const useState = legacyAdapters.useState
export const useApiEndpoint = legacyAdapters.useApiEndpoint
export const useAuth = legacyAdapters.useAuth
export const useAuthStore = legacyAdapters.useAuthStore
export const useRoute = legacyAdapters.useRoute

// Export des fonctions de composition Vue
export { ref, computed }

// Export des fonctions
export const useCookie = legacyAdapters.useCookie

// Pont entre les composables Nuxt3 et Vue2
export function setupComposables(Vue) {
    Vue.prototype.$nuxt = {
        state: {}
    }
    
    Vue.prototype.$composables = {
        useNavigation: adaptedUseNavigation,
        useApiEndpoint: legacyAdapters.useApiEndpoint,
        useAuth: legacyAdapters.useAuth,
        useAuthStore: legacyAdapters.useAuthStore,
        useRoute: legacyAdapters.useRoute
    }
}

// Export des types pour TypeScript
export type { NavItem } from './frontend/composables/useNavigation' 

// Expose le composable globalement pour l'app legacy
if (typeof window !== 'undefined') {
    window.useApiEndpoint = useApiEndpoint;
}

// Émet un événement quand les composables sont prêts
document.dispatchEvent(new Event('vue-components-ready')); 