import { ref, computed } from 'vue'

interface User {
  id: number
  nom: string
  prenom: string
  email: string
  level?: string
}

export function useAuth() {
  const user = useState<User | null>('auth.user', () => null)
  const token = useCookie('auth_token')

  const isAuthenticated = computed(() => !!user.value && !!token.value)
  const isAdmin = computed(() => user.value?.level === '0')

  async function login(credentials: { login: string; password: string }) {
    const { endpoint } = useApiEndpoint()
    
    try {
      const response = await $fetch(`${endpoint.value}/auth/login`, {
        method: 'POST',
        body: credentials
      })

      if (response.data?.user && response.data?.token) {
        user.value = response.data.user
        token.value = response.data.token
        return { success: true }
      }

      return { success: false, error: 'Format de réponse invalide' }
    } catch (error: any) {
      console.error('Login error:', error)
      return { 
        success: false, 
        error: error.response?._data?.message || 'Erreur serveur'
      }
    }
  }

  function logout() {
    user.value = null
    token.value = null
    navigateTo('/login')
  }

  return {
    user,
    token,
    isAuthenticated,
    isAdmin,
    login,
    logout
  }
} 