import { ref, computed } from 'vue'
import { useRoute, useState } from '#app'

export const useApiEndpoint = () => {
  const STORAGE_KEY = 'api_endpoint'
  
  // Récupère l'endpoint depuis l'URL ou le localStorage
  const initEndpoint = () => {
    // Vérifie d'abord dans l'URL
    const route = useRoute()
    const urlEndpoint = route.query.endpoint as string
    
    if (urlEndpoint) {
      // Si présent dans l'URL, sauvegarde et retourne
      localStorage.setItem(STORAGE_KEY, urlEndpoint)
      return urlEndpoint
    }
    
    // Sinon récupère depuis le localStorage
    return localStorage.getItem(STORAGE_KEY)
  }
  
  // État réactif pour l'endpoint
  const endpoint = useState('apiEndpoint', () => initEndpoint())
  
  // Vérifie si un endpoint est configuré
  const isConfigured = computed(() => !!endpoint.value)
  
  // Met à jour l'endpoint
  const setEndpoint = (newEndpoint: string) => {
    endpoint.value = newEndpoint
    localStorage.setItem(STORAGE_KEY, newEndpoint)
  }
  
  // Supprime l'endpoint
  const clearEndpoint = () => {
    endpoint.value = null
    localStorage.removeItem(STORAGE_KEY)
  }
  
  return {
    endpoint,
    isConfigured,
    setEndpoint,
    clearEndpoint
  }
} 