<template>
  <div>
    <h1>{{ title }}</h1>
  </div>
</template>

<script setup>
import { ref } from 'vue-demi';

const title = ref('Hello from a Vue 2 and 3 Component!');
</script>

<style scoped>
/* styles spécifiques au composant */
</style>