import { ref, computed } from 'vue'
import { useApiEndpoint } from './useApiEndpoint'
import { useAuth } from './useAuth'
import { navigateTo } from '#app'

export interface NavItem {
  id?: number | null
  label: string
  icon: string
  route: string
  external?: boolean
  children?: NavItem[] | null
  requiredPermissions?: string[]
}

export const useNavigation = () => {
  // Récupération de l'endpoint avec fallback sur la config globale
  const { endpoint } = useApiEndpoint()
  const apiEndpoint = computed(() => {
    if (endpoint.value) return endpoint.value
    if (window.API_ENDPOINT_CONFIG?.defaultEndpoint) {
      return window.API_ENDPOINT_CONFIG.defaultEndpoint
    }
    return '/api/v2'
  })

  const { user, token, logout } = useAuth()
  const menuItems = ref<NavItem[]>([])
  const isLoading = ref(true)
  const error = ref<string | null>(null)

  // Cache des menus par rôle
  const menuCache = new Map<string, NavItem[]>()

  const fetchMenuItems = async () => {
    try {
      console.log('Navigation: Début fetchMenuItems')
      console.log('Navigation: API endpoint from composable =', endpoint.value)
      console.log('Navigation: API endpoint from config =', window.API_ENDPOINT_CONFIG?.defaultEndpoint)
      console.log('Navigation: API endpoint final =', apiEndpoint.value)
      
      isLoading.value = true
      const userRole = user.value?.role

      if (menuCache.has(userRole)) {
        console.log('Using cached menu items')
        menuItems.value = menuCache.get(userRole) || []
        isLoading.value = false
        return
      }

      console.log('Navigation: URL complète =', `${apiEndpoint.value}/site-navigations/main/items`)
      const response = await fetch(`${apiEndpoint.value}/site-navigations/main/items`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token.value}`
        }
      })

      console.log('Navigation: Status réponse =', response.status)

      if (!response.ok) {
        if (response.status === 401) {
          await logout()
          await navigateTo('/login', {
            query: { redirect: window.location.pathname }
          })
          throw new Error('Unauthorized')
        }
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log('Navigation: Données reçues =', data)

      if (data?.data?.items) {
        const transformedItems = data.data.items.map((item: NavItem) => ({
          ...item,
          requiredPermissions: []
        }))
        
        menuItems.value = transformedItems
        if (userRole) {
          menuCache.set(userRole, transformedItems)
        }
      } else {
        error.value = "Format de réponse invalide"
      }

    } catch (e: any) {
      console.error('Navigation: Erreur lors du fetch:', e)
      error.value = e.message || "Erreur lors du chargement du menu"
      throw e
    } finally {
      isLoading.value = false
    }
  }

  return {
    menuItems: computed(() => menuItems.value),
    isLoading,
    error,
    fetchMenuItems
  }
} 