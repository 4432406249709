<template>
  <div class="pageHaut" id="Layer2">
    <b-navbar toggleable="lg" variant="primary" type="dark" class="py-0">
      <b-navbar-brand to="/" class="py-1 px-3">
        <img :src="logo" height="55" alt="Logo">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" class="ms-auto me-2" />
      <b-collapse id="nav-collapse" is-nav class="flex-grow-1">
        <b-navbar-nav class="w-100 nav-fill align-items-center">
          <!-- Loading State -->
          <div v-if="isLoading" class="nav-item-custom px-2">
            <b-spinner small variant="light"></b-spinner>
          </div>

          <!-- Error State -->
          <div v-else-if="error" class="nav-item-custom px-2 text-warning">
            <i class="fa-solid fa-exclamation-triangle"></i>
            <span class="nav-text">Erreur de menu</span>
          </div>

          <!-- Menu Items -->
          <template v-else v-for="item in menuItems" :key="item.id || item.route">
            <!-- Regular Nav Item -->
            <b-nav-item 
              v-if="!item.children && !item.external" 
              :href="item.route"
              class="nav-item-custom px-2"
            >
              <div class="nav-icon">
                <i :class="item.icon"></i>
              </div>
              <span class="nav-text">{{ item.label }}</span>
            </b-nav-item>

            <!-- External Link -->
            <b-nav-item 
              v-else-if="!item.children && item.external"
              :href="item.route"
              target="_blank"
              class="nav-item-custom px-2"
            >
              <div class="nav-icon">
                <i :class="item.icon"></i>
              </div>
              <span class="nav-text">{{ item.label }}</span>
            </b-nav-item>

            <!-- Dropdown -->
            <BNavItemDropdown
              v-else
              right
              class="nav-item-custom px-2"
              no-caret
              menu-class="px-1"
            >
              <template #button-content>
                <div class="d-flex flex-column align-items-center">
                  <div class="nav-icon">
                    <i :class="item.icon"></i>
                  </div>
                  <span class="nav-text">{{ item.label }}</span>
                </div>
              </template>
              <BDropdownItem
                v-for="child in item.children"
                :key="child.id || child.route"
                :href="child.route"
              >
                <i :class="child.icon + ' me-2'"></i>
                {{ child.label }}
              </BDropdownItem>
            </BNavItemDropdown>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

// Utilisation d'une URL statique pour le logo
const logo = ref('/images/logo_navbar.png') // Ajustez le chemin selon votre structure

// S'assurer que window.useNavigation existe
const navigation = window.useNavigation ? window.useNavigation() : { 
    menuItems: ref([]), 
    isLoading: ref(false), 
    error: ref(null), 
    fetchMenuItems: async () => {} 
}

const { menuItems, isLoading, error, fetchMenuItems } = navigation

onMounted(async () => {
  try {
    if (fetchMenuItems) {
      await fetchMenuItems()
    }
  } catch (e) {
    console.error('Erreur lors du chargement du menu:', e)
  }
})
</script>

<style>
.nav-item-custom {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-icon {
  font-size: 18px;
  margin-bottom: 4px;
  line-height: 1;
  color: var(--white);
}

.nav-text {
  font-size: 12px;
  white-space: nowrap;
  line-height: 1;
  color: var(--white);
}

/* Hover effect */
.nav-item-custom:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Active state */
.nav-item-custom.active {
  background-color: rgba(255, 255, 255, 0.15);
}

@media (max-width: 991.98px) {
  .nav-item-custom {
    padding: 0.5rem;
  }
}

/* Supprimer les carets par défaut */
:deep(.dropdown-toggle::after) {
  display: none !important;
}

/* Ajout de styles spécifiques pour le dropdown utilisateur */
.user-dropdown .nav-text {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style> 